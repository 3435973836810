export const sessionStoragePatient = (data, country) => {
  sessionStorage.removeItem('approvedPrograms');
  sessionStorage.setItem('nombre', data.nombre);
  sessionStorage.setItem('apellido', data.apellido);
  sessionStorage.setItem('email', data.email);
  sessionStorage.setItem('edad', data.edad);
  sessionStorage.setItem('genero', data.genero);
  sessionStorage.setItem('vid', data.vid);
  sessionStorage.setItem('address', JSON.stringify(data.direcciones_sucursales));
  sessionStorage.setItem('ownerId', data.med_assistance.owner_id);
  sessionStorage.setItem('nhc', data.nhc);
  sessionStorage.setItem('doc_info', `${data.doc_info.firstname} ${data.doc_info.lastname}`);
  sessionStorage.setItem('med_assistance', `${data.med_assistance.firstname} ${data.med_assistance.lastname}`);
  sessionStorage.setItem('flagOwner', data.med_assistance.owner_id === '' ? '0' : '1');
  sessionStorage.setItem('subtipo', data.subtipo);
  sessionStorage.setItem('tipo', data.tipo);
  if (country === 'US') {
    sessionStorage.setItem('conocimiento', JSON.stringify(data.via_score));
  } else {
    sessionStorage.setItem(
      'buyer-score',
      JSON.stringify({ buyer: data.puntaje_buyer_persona, color: data.color, score: data.score_socioecon })
    );
  }
};
